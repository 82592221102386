const questions = [
    {
        id: 1,
        question: "چرا می‌خواهید برنامه‌نویسی یاد بگیرید؟",
        options: ["برای تحت تأثیر قرار دادن دوستان", "برای اجتناب از سایر موضوعات", "برای گذراندن وقت", "برای حل مشکلات و ایجاد راه‌حل‌ها"],
        answer: "برای حل مشکلات و ایجاد راه‌حل‌ها"
    },
    {
        id: 2,
        question: "نظرتان درباره حل معماها و مسائل منطقی چیست؟",
        options: ["بی‌تفاوت هستم", "از آن‌ها اجتناب می‌کنم", "آن‌ها را خسته‌کننده می‌دانم", "از آن‌ها لذت می‌برم و چالش‌برانگیز می‌دانم"],
        answer: "از آن‌ها لذت می‌برم و چالش‌برانگیز می‌دانم"
    },
    {
        id: 3,
        question: "رویکرد شما به یادگیری مفاهیم جدید چیست؟",
        options: ["علاقه‌ای به یادگیری چیزهای جدید ندارم", "آن را دشوار و ناامیدکننده می‌دانم", "منتظر می‌مانم تا کسی به من آموزش دهد", "کنجکاو و فعال هستم"],
        answer: "کنجکاو و فعال هستم"
    },
    {
        id: 4,
        question: "چگونه با اشتباهات برخورد می‌کنید؟",
        options: ["آن‌ها را به عنوان فرصت‌های یادگیری می‌بینم", "به راحتی دلسرد می‌شوم", "آن‌ها را نادیده می‌گیرم", "دیگران را مقصر می‌دانم"],
        answer: "آن‌ها را به عنوان فرصت‌های یادگیری می‌بینم"
    },
    {
        id: 5,
        question: "تجربه شما در استفاده از کامپیوتر چیست؟",
        options: ["راحت هستم و به طور منظم از آن‌ها استفاده می‌کنم", "گاهی از آن‌ها استفاده می‌کنم", "به ندرت از آن‌ها استفاده می‌کنم", "از استفاده از آن‌ها اجتناب می‌کنم"],
        answer: "راحت هستم و به طور منظم از آن‌ها استفاده می‌کنم"
    },
    {
        id: 6,
        question: "نظرتان درباره کار بر روی پروژه‌های بلندمدت چیست؟",
        options: [" از تعهدات بلندمدت اجتناب می‌کنم", "به سرعت علاقه‌ام را از دست می‌دهم", "وظایف کوتاه را ترجیح می‌دهم", "هیجان‌زده و متعهد هستم"],
        answer: "هیجان‌زده و متعهد هستم"
    },
    {
        id: 7,
        question: "نگرش شما نسبت به یادگیری مداوم چیست؟",
        options: ["بی‌تفاوت هستم", "از یادگیری چیزهای جدید اجتناب می‌کنم", "فقط در صورت لزوم یاد می‌گیرم", "مشتاق یادگیری و بهبود هستم"],
        answer: "مشتاق یادگیری و بهبود هستم"
    },
    {
        id: 8,
        question: "چگونه به حل مسئله نزدیک می‌شوید؟",
        options: ["مسائل را به بخش‌های کوچکتر تقسیم می‌کنم", "به دنبال راه‌حل‌های سریع هستم", "از مسائل اجتناب می‌کنم", "به دیگران برای حل مسائل تکیه می‌کنم"],
        answer: "به دیگران برای حل مسائل تکیه می‌کنم"
    },
    {
        id: 9,
        question: "سطح صبر شما هنگام کار بر روی وظایف پیچیده چگونه است؟",
        options: ["از وظایف پیچیده اجتناب می‌کنم", "به سرعت تسلیم می‌شوم", "به راحتی ناامید می‌شوم", "صبور و پایدار هستم"],
        answer: "صبور و پایدار هستم"
    },
    {
        id: 10,
        question: "نظرتان درباره همکاری با دیگران در پروژه‌ها چیست؟",
        options: ["از همکاری اجتناب می‌کنم", "آن را چالش‌برانگیز می‌دانم", "ترجیح می‌دهم به تنهایی کار کنم", "از کار تیمی و همکاری لذت می‌برم"],
        answer: "از کار تیمی و همکاری لذت می‌برم"
    },
    {
        id: 11,
        question: "نظرتان درباره یادگیری فناوری‌ها و ابزارهای جدید چیست؟",
        options: ["از یادگیری فناوری‌های جدید اجتناب می‌کنم", "آن را طاقت‌فرسا می‌دانم", "فقط در صورت لزوم یاد می‌گیرم", "هیجان‌زده و مشتاق یادگیری هستم"],
        answer: "هیجان‌زده و مشتاق یادگیری هستم"
    },
    {
        id: 12,
        question: "رویکرد شما به رفع مشکلات فنی چیست؟",
        options: ["مشکلات را نادیده می‌گیرم", "بلافاصله درخواست کمک می‌کنم", "تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم", "ناامید می‌شوم و تسلیم می‌شوم"],
        answer: "تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم"
    },
    {
        id: 13,
        question: "نظر شما در مورد یادگیری فناوری‌ها و ابزارهای جدید چیست؟",
        options: ["از یادگیری فناوری‌های جدید اجتناب می‌کنم", "آن را طاقت‌فرسا می‌دانم", "فقط در صورت لزوم یاد می‌گیرم", "من هیجان‌زده و مشتاق یادگیری هستم"],
        answer: "من هیجان‌زده و مشتاق یادگیری هستم"
    },
    {
        id: 14,
        question: "رویکرد شما برای رفع مشکلات فنی چیست؟ ",
        options: ["ناامید می‌شوم و تسلیم می‌شوم", "مشکلات را نادیده می‌گیرم ", "بلافاصله درخواست کمک می‌کنم ", "تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم "],
        answer: "تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم "
    },
    {
        id: 15,
        question: "چگونه زمان خود را هنگام کار بر روی پروژه‌ها مدیریت می‌کنید؟",
        options: ["به تعویق می‌اندازم", "در مدیریت زمان مشکل دارم", "وظایف را به محض رسیدن انجام می‌دهم ", "برنامه‌ریزی می‌کنم و وظایف را به‌طور مؤثر اولویت‌بندی می‌کنم "],
        answer: "برنامه‌ریزی می‌کنم و وظایف را به‌طور مؤثر اولویت‌بندی می‌کنم "
    },
    {
        id: 16,
        question: "چه چیزی شما را برای انجام وظایف چالش‌برانگیز انگیزه می‌دهد؟",
        options: ["از وظایف چالش‌برانگیز اجتناب می‌کنم", "اجتناب از پیامدهای منفی", "پاداش‌های خارجی", "حس موفقیت"],
        answer: "حس موفقیت"
    },
    {
        id: 17,
        question: "چگونه از اشتباهات خود یاد می‌گیرید؟",
        options: ["عوامل خارجی را مقصر می‌دانم", "احساس دلسردی می‌کنم", "سعی می‌کنم آن‌ها را فراموش کنم", "آن‌ها را تحلیل می‌کنم و از آن‌ها درس می‌گیرم"],
        answer: "آن‌ها را تحلیل می‌کنم و از آن‌ها درس می‌گیرم"
    },
    {
        id: 18,
        question: "تجربه شما با تفکر منطقی و استدلال چگونه است؟",
        options: ["از آن اجتناب می‌کنم", "با آن مشکل دارم", "آن را به‌طور متوسط چالش‌برانگیز می‌دانم", "از آن لذت می‌برم و در آن مهارت دارم"],
        answer: "از آن لذت می‌برم و در آن مهارت دارم"
    },
    {
        id: 19,
        question: "نظر شما در مورد پیروی از دستورالعمل‌های دقیق چیست؟",
        options: ["ترجیح می‌دهم خودم راه‌حل پیدا کنم", "آن را خسته‌کننده می‌دانم", "آن‌ها را به‌طور سطحی مرور می‌کنم", "با دقت از آن‌ها پیروی می‌کنم"],
        answer: "با دقت از آن‌ها پیروی می‌کنم"
    },
    {
        id: 20,
        question: "رویکرد شما برای بهبود مستمر چیست؟",
        options: ["بی‌تفاوت هستم", "در برابر تغییر مقاومت می‌کنم ", "فقط در صورت لزوم بهبود می‌بخشم", "فعالانه بازخورد می‌گیرم و بهبود می‌بخشم "],
        answer: "فعالانه بازخورد می‌گیرم و بهبود می‌بخشم "
    },
    {
        id: 21,
        question: "چگونه با مفاهیم پیچیده و انتزاعی برخورد می‌کنید؟",
        options: ["از آن‌ها اجتناب می‌کنم", "با آن‌ها مشکل دارم", "آن‌ها را چالش‌برانگیز اما قابل مدیریت می‌دانم", "آن‌ها را به بخش‌های ساده‌تر تقسیم می‌کنم"],
        answer: "آن‌ها را به بخش‌های ساده‌تر تقسیم می‌کنم"
    },
    {
        id: 22,
        question: "نظر شما در مورد آزمایش ایده‌های جدید چیست؟",
        options: ["از آزمایش اجتناب می‌کنم", "آن را پرخطر می‌دانم", "روش‌های امتحان‌شده و واقعی را ترجیح می‌دهم", "من باز و مایل به آزمایش هستم"],
        answer: "من باز و مایل به آزمایش هستم"
    },
    {
        id: 23,
        question: "چه احساسی نسبت به کار با ساختارهای داده انتزاعی مانند درخت‌ها و گراف‌ها دارید؟",
        options: ["از آنها اجتناب می‌کنم", "آنها را گیج‌کننده می‌دانم", "مایل به یادگیری درباره آنها هستم", "آنها را جذاب و چالش‌برانگیز می‌دانم"],
        answer: "آنها را جذاب و چالش‌برانگیز می‌دانم"
    },
    {
        id: 24,
        question: "رویکرد شما به یادگیری از منابع و آموزش‌های آنلاین چیست؟",
        options: ["یادگیری حضوری را ترجیح می‌دهم", "آنها را دشوار می‌دانم", "گاهی از آنها استفاده می‌کنم", "فعالانه به دنبال آنها می‌گردم و همراهی می‌کنم"],
        answer: "فعالانه به دنبال آنها می‌گردم و همراهی می‌کنم"
    },
    {
        id: 25,
        question: "چگونه با وظایف تکراری برخورد می‌کنید؟ ",
        options: ["به دنبال راه‌هایی برای خودکارسازی آنها هستم", "از آنها اجتناب می‌کنم", "به سرعت خسته می‌شوم", "آنها را در صورت نیاز انجام می‌دهم"],
        answer: "به دنبال راه‌هایی برای خودکارسازی آنها هستم"
    },
    {
        id: 26,
        question: "تجربه شما با مفاهیم ریاضی در برنامه‌نویسی چگونه است؟",
        options: ["از آنها اجتناب می‌کنم", "آنها را چالش‌برانگیز می‌دانم", "می‌توانم مفاهیم پایه را مدیریت کنم", "با آنها راحت هستم و از استفاده از آنها لذت می‌برم"],
        answer: "با آنها راحت هستم و از استفاده از آنها لذت می‌برم"
    },
    {
        id: 27,
        question: "چه احساسی نسبت به همکاری در پروژه‌های متن‌باز دارید؟",
        options: ["ترجیح می‌دهم روی پروژه‌های خصوصی کار کنم", "آن را ترسناک می‌دانم", "علاقه‌مند هستم اما نمی‌دانم از کجا شروع کنم", "هیجان‌زده و مشتاق به مشارکت هستم"],
        answer: "هیجان‌زده و مشتاق به مشارکت هستم"
    },
    {
        id: 28,
        question: "رویکرد شما به به‌روز ماندن با روندها و فناوری‌های جدید برنامه‌نویسی چیست؟",
        options: ["علاقه‌ای ندارم", "آن را طاقت‌فرسا می‌دانم", "در صورت نیاز درباره آنها یاد می‌گیرم", "فعالانه اخبار و به‌روزرسانی‌های صنعت را دنبال می‌کنم"],
        answer: "فعالانه اخبار و به‌روزرسانی‌های صنعت را دنبال می‌کنم"
    },
    {
        id: 29,
        question: "چه احساسی نسبت به نوشتن مستندات برای کد خود دارید؟",
        options: ["از آن اجتناب می‌کنم", "آن را خسته‌کننده می‌دانم", "در صورت نیاز آن را انجام می‌دهم", "آن را بخشی ضروری از فرآیند می‌دانم"],
        answer: "آن را بخشی ضروری از فرآیند می‌دانم"
    },
    {
        id: 30,
        question: "چقدر به کار با پروژه‌های منبع باز علاقه دارید؟",
        options: ["ترجیح می‌دهم روی پروژه‌های خصوصی کار کنم", "کار با پروژه‌های منبع باز را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم با پروژه‌های منبع باز کار کنم ولی کمی زمان می‌برد", " از کار با پروژه‌های منبع باز لذت می‌برم و همیشه به دنبال مشارکت هستم"],
        answer: " از کار با پروژه‌های منبع باز لذت می‌برم و همیشه به دنبال مشارکت هستم"
    },
    {
        id: 31,
        question: "چگونه به یادگیری یک زبان برنامه‌نویسی جدید نزدیک می‌شوید؟",
        options: ["به سرعت شروع به کدنویسی می‌کنم", "آموزش‌ها و دوره‌های", "آن را چالش‌برانگیز می‌دانم", "از یادگیری زبان‌های جدید اجتناب می‌کنم"],
        answer: "آموزش‌ها و دوره‌های"
    },
    {
        id: 32,
        question: "نگرش شما نسبت به تست کد خود چیست؟",
        options: ["آن را یک مرحله حیاتی می‌دانم", "در صورت نیاز آن را انجام می‌دهم", "آن را وقت‌گیر می دانم", "از آن اجتناب می‌کنم"],
        answer: "آن را یک مرحله حیاتی می‌دانم"
    },
    {
        id: 33,
        question: "چقدر به حل معماها و مشکلات منطقی علاقه دارید؟",
        options: ["اصلاً لذت نمی‌برم", " چالش‌برانگیز است ولی جالب", "گاهی از آن لذت می‌برم", "عاشقش هستم و اغلب انجام می‌دهم"],
        answer: "عاشقش هستم و اغلب انجام می‌دهم"
    },
    {
        id: 34,
        question: "وقتی با یک مشکل دشوار مواجه می‌شوید، چه رویکردی دارید؟",
        options: ["ناامید می‌شوم و تسلیم می‌شوم", "از دیگران کمک می‌گیرم", "به دنبال مشکلات مشابه می‌گردم و می‌بینم چگونه حل شده‌اند", "آن را به بخش‌های کوچکتر تقسیم کرده و هر یک را حل می‌کنم"],
        answer: "آن را به بخش‌های کوچکتر تقسیم کرده و هر یک را حل می‌کنم"
    },
    {
        id: 35,
        question: "چگونه با وظایف تکراری برخورد می‌کنید؟",
        options: [" تا حد ممکن از آنها اجتناب می‌کنم", "سعی می‌کنم راهی کارآمدتر برای انجام آنها پیدا کنم", "آنها را انجام می‌دهم ولی خسته‌کننده هستند", "به دنبال راه‌هایی برای خودکارسازی آنها هستم"],
        answer: "به دنبال راه‌هایی برای خودکارسازی آنها هستم"
    },
    {
        id: 36,
        question: "چقدر با یادگیری فناوری‌ها و ابزارهای جدید راحت هستید؟",
        options: ["ناراحت، آن را طاقت‌فرسا می‌دانم", "تا حدودی راحت، ولی ترجیح می‌دهم به چیزهایی که می‌دانم پایبند باشم", "راحت، ولی کمی زمان می‌برد", "بسیار راحت، از یادگیری چیزهای جدید لذت می‌برم"],
        answer: "بسیار راحت، از یادگیری چیزهای جدید لذت می‌برم"
    },
    {
        id: 37,
        question: "چقدر به کار بر روی پروژه‌هایی که نیاز به دقت دارند علاقه دارید؟",
        options: ["با آن مشکل دارم و ترجیح می‌دهم وظایف کلی‌تر را انجام دهم", "چالش‌برانگیز است ولی قابل مدیریت", "می‌توانم انجام دهم، ولی مورد علاقه‌ام نیست", "از آن لذت می‌برم و بسیار دقیق هستم"],
        answer: "از آن لذت می‌برم و بسیار دقیق هستم"
    },
    {
        id: 38,
        question: "واکنش شما به مواجهه با خطاها یا باگ‌ها در کارتان چیست؟",
        options: ["ناامید می‌شوم و انگیزه‌ام را از دست می‌دهم", "سعی می‌کنم سریعاً آن را رفع کرده و ادامه دهم", "آن را خسته‌کننده ولی ضروری می‌دانم", "آن را به عنوان یک چالش می‌بینم و از رفع باگ لذت می‌برم"],
        answer: "آن را به عنوان یک چالش می‌بینم و از رفع باگ لذت می‌برم"
    },
    {
        id: 39,
        question: "چگونه ترجیح می‌دهید مهارت‌های جدید را یاد بگیرید؟",
        options: ["با خواندن کتاب‌ها و مقالات", "با شرکت در کلاس‌ها یا دوره‌های رسمی", "با دنبال کردن آموزش‌ها و راهنماها", "از طریق تمرین عملی و آزمایش"],
        answer: "از طریق تمرین عملی و آزمایش"
    },
    {
        id: 40,
        question: "چقدر به همکاری با دیگران در پروژه‌ها علاقه دارید؟",
        options: ["ترجیح می‌دهم به صورت مستقل کار کنم", "آن را مفید ولی گاهی چالش‌برانگیز می‌دانم", "می‌توانم انجام دهم، ولی ترجیح می‌دهم تنها کار کنم", "از آن لذت می‌برم و مفید می‌دانم"],
        answer: "از آن لذت می‌برم و مفید می‌دانم"
    },
    {
        id: 41,
        question: "چقدر به بهبود مداوم مهارت‌های خود علاقه دارید؟",
        options: ["از سطح مهارت فعلی خود راضی هستم", "فقط وقتی لازم باشد بهبود پیدا می‌کنم", "سعی می‌کنم وقتی زمان دارم بهبود پیدا کنم", "همیشه به دنبال راه‌هایی برای بهبود هستم"],
        answer: "همیشه به دنبال راه‌هایی برای بهبود هستم"
    },
    {
        id: 42,
        question: "چگونه از اشتباهات خود یاد می‌گیرید؟",
        options: ["یادگیری از اشتباهات برایم سخت است", "احساس ناامیدی می‌کنم ولی سعی می‌کنم ادامه دهم", "سعی می‌کنم از تکرار اشتباهات جلوگیری کنم", "اشتباهاتم را تحلیل کرده و از آنها یاد می‌گیرم"],
        answer: "اشتباهاتم را تحلیل کرده و از آنها یاد می‌گیرم"
    },
    {
        id: 43,
        question: "چقدر به آزمایش ایده‌های جدید علاقه دارید؟",
        options: ["تا حد ممکن از آزمایش اجتناب می‌کنم", "ترجیح می‌دهم به روش‌های اثبات شده پایبند باشم", "به ایده‌های جدید باز هستم ولی محتاط", "عاشق آزمایش و امتحان چیزهای جدید هستم"],
        answer: "عاشق آزمایش و امتحان چیزهای جدید هستم"
    },
    {
        id: 44,
        question: "چگونه با بازخورد در مورد کارتان برخورد می‌کنید؟",
        options: ["بازخورد را دلسردکننده می‌دانم", "احساس دفاعی می‌کنم ولی سعی می‌کنم در نظر بگیرم", "بازخورد را می‌پذیرم ولی اجرای آن برایم سخت است", "از بازخورد قدردانی کرده و از آن برای بهبود استفاده می‌کنم"],
        answer: "از بازخورد قدردانی کرده و از آن برای بهبود استفاده می‌کنم"
    },
    {
        id: 45,
        question: "چگونه با فشار و ضرب‌الاجل‌ها برخورد می‌کنید؟",
        options: ["می‌توانم با فشار و ضرب‌الاجل‌ها کنار بیایم ولی کمی استرس‌زا است", "با فشار و ضرب‌الاجل‌ها مشکل دارم و ترجیح می‌دهم به برنامه‌های بدون فشار پایبند باشم", "فشار و ضرب‌الاجل‌ها را چالش‌برانگیز ولی قابل مدیریت می‌دانم", "به خوبی با فشار و ضرب‌الاجل‌ها کنار می‌آیم و کارم را به موقع انجام می‌دهم"],
        answer: "به خوبی با فشار و ضرب‌الاجل‌ها کنار می‌آیم و کارم را به موقع انجام می‌دهم"
    },
    {
        id: 46,
        question: "چقدر به کار با پروژه‌های مبتنی بر هوش مصنوعی علاقه دارید؟",
        options: ["ترجیح می‌دهم روی پروژه‌های سنتی‌تر کار کنم", "کار با پروژه‌های مبتنی بر هوش مصنوعی را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم با پروژه‌های مبتنی بر هوش مصنوعی کار کنم ولی کمی زمان می‌برد", "از کار با پروژه‌های مبتنی بر هوش مصنوعی لذت می‌برم و همیشه به دنبال یادگیری هستم"],
        answer: "از کار با پروژه‌های مبتنی بر هوش مصنوعی لذت می‌برم و همیشه به دنبال یادگیری هستم"
    },
    {
        id: 47,
        question: "چقدر به کار با ابزارهای توسعه نرم‌افزار علاقه دارید؟",
        options: ["ترجیح می‌دهم به روش‌های سنتی توسعه نرم‌افزار پایبند باشم", "ابزارهای توسعه نرم‌افزار را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم با ابزارهای توسعه نرم‌افزار کار کنم ولی همیشه راحت نیست", "از کار با ابزارهای توسعه نرم‌افزار لذت می‌برم و آنها را مفید می‌دانم"],
        answer: "از کار با ابزارهای توسعه نرم‌افزار لذت می‌برم و آنها را مفید می‌دانم"
    },
    {
        id: 48,
        question: "چقدر به کار با تیم‌های بین‌المللی علاقه دارید؟",
        options: ["ترجیح می‌دهم با تیم‌های محلی کار کنم", "کار با تیم‌های بین‌المللی را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم با تیم‌های بین‌المللی کار کنم ولی گاهی چالش‌برانگیز است", "از کار با تیم‌های بین‌المللی لذت می‌برم و آن را مفید می‌دانم"],
        answer: "از کار با تیم‌های بین‌المللی لذت می‌برم و آن را مفید می‌دانم"
    },
    {
        id: 49,
        question: "چگونه با پروژه‌هایی که نیاز به خلاقیت دارند برخورد می‌کنید؟",
        options: ["ترجیح می‌دهم روی پروژه‌های ساختاریافته کار کنم", "پروژه‌های خلاقانه را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم پروژه‌های خلاقانه را مدیریت کنم ولی همیشه ایده‌های جدید ندارم", "از پروژه‌های خلاقانه لذت می‌برم و ایده‌های جدید ارائه می‌دهم"],
        answer: "از پروژه‌های خلاقانه لذت می‌برم و ایده‌های جدید ارائه می‌دهم"
    },
    {
        id: 50,
        question: "چقدر به کار با ابزارهای مدیریت پروژه علاقه دارید؟",
        options: ["می‌توانم با ابزارهای مدیریت پروژه کار کنم ولی همیشه راحت نیست", "ترجیح می‌دهم به روش‌های سنتی مدیریت پروژه پایبند باشم", "ابزارهای مدیریت پروژه را چالش‌برانگیز ولی جالب می‌دانم", "از کار با ابزارهای مدیریت پروژه لذت می‌برم و آنها را مفید می‌دانم"],
        answer: "از کار با ابزارهای مدیریت پروژه لذت می‌برم و آنها را مفید می‌دانم"
    },
    // {
    //     id: 0,
    //     question: "",
    //     options: ["", "", "", ""],
    //     answer: ""
    // }
]


// eslint-disable-next-line import/prefer-default-export
export {questions}