import {
    HomeOutlined
} from '@ant-design/icons';
import {header} from '../../portfolio'
import './Header.css'
import NavbarPage from "../Navbar/NavbarPage";

const HeaderPage = () => {
    const {homepage, title} = header

    return (
        <header className='header center'>
            <h4>
                {homepage ? (
                    <a href={homepage} className='link'>
                        {/* {title} */}
                        <HomeOutlined />
                    </a>
                ) : (
                    title
                )}
            </h4>
            <NavbarPage/>
        </header>
    )
}

export default HeaderPage
