import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, List, Progress, Radio, Space, Statistic, Typography} from "antd";
import {Helmet} from "react-helmet";
import Footer from "../../Footer/Footer";
import {ThemeContext} from "../../../contexts/theme";
import HeaderPage from "../../Header/HeaderPage";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import "./Question.css"
import 'antd/dist/antd.css';
import Contact from "../../Contact/Contact";
import {questions} from '../../../questions'
import {about, aboutpersion} from "../../../portfolio";

const {Title} = Typography;

// const questions = [
//     {
//         id: 1,
//         question: "پایتخت ایران کجاست؟",
//         options: ["تهران", "مشهد", "اصفهان", "شیراز"],
//         answer: "تهران"
//     },
//     {
//         id: 2,
//         question: "بزرگترین سیاره در منظومه شمسی کدام است؟",
//         options: ["زمین", "مریخ", "مشتری", "زحل"],
//         answer: "مشتری"
//     },
//     {
//         id: 3,
//         question: "چه سالی انقلاب اسلامی ایران رخ داد؟",
//         options: ["۱۹۷۹", "۱۹۸۰", "۱۹۸۱", "۱۹۸۲"],
//         answer: "۱۹۷۹"
//     }
// ];

// const questions = [
//     {
//         questionText: 'چرا می‌خواهید برنامه‌نویسی یاد بگیرید؟',
//         answerOptions: [
//             {answerText: 'برای حل مشکلات و ایجاد راه‌حل‌ها', isCorrect: true},
//             {answerText: 'برای گذراندن وقت', isCorrect: false},
//             {answerText: 'برای اجتناب از سایر موضوعات', isCorrect: false},
//             {answerText: 'برای تحت تأثیر قرار دادن دوستان', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'نظرتان درباره حل معماها و مسائل منطقی چیست؟',
//         answerOptions: [
//             {answerText: 'از آن‌ها لذت می‌برم و چالش‌برانگیز می‌دانم', isCorrect: false},
//             {answerText: 'آن‌ها را خسته‌کننده می‌دانم', isCorrect: true},
//             {answerText: 'از آن‌ها اجتناب می‌کنم', isCorrect: false},
//             {answerText: 'بی‌تفاوت هستم', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'رویکرد شما به یادگیری مفاهیم جدید چیست؟',
//         answerOptions: [
//             {answerText: 'کنجکاو و فعال هستم', isCorrect: false},
//             {answerText: 'منتظر می‌مانم تا کسی به من آموزش دهد', isCorrect: true},
//             {answerText: 'آن را دشوار و ناامیدکننده می‌دانم', isCorrect: false},
//             {answerText: 'علاقه‌ای به یادگیری چیزهای جدید ندارم', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'چگونه با اشتباهات برخورد می‌کنید؟',
//         answerOptions: [
//             {answerText: 'آن‌ها را به عنوان فرصت‌های یادگیری می‌بینم', isCorrect: false},
//             {answerText: 'به راحتی دلسرد می‌شوم', isCorrect: true},
//             {answerText: 'آن‌ها را نادیده می‌گیرم', isCorrect: false},
//             {answerText: 'دیگران را مقصر می‌دانم', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'نظرتان درباره کار بر روی پروژه‌های بلندمدت چیست؟',
//         answerOptions: [
//             {answerText: 'هیجان‌زده و متعهد هستم', isCorrect: false},
//             {answerText: 'وظایف کوتاه را ترجیح می‌دهم', isCorrect: true},
//             {answerText: 'به سرعت علاقه‌ام را از دست می‌دهم', isCorrect: false},
//             {answerText: 'از تعهدات بلندمدت اجتناب می‌کنم', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'چگونه به حل مسئله نزدیک می‌شوید؟',
//         answerOptions: [
//             {answerText: 'مسائل را به بخش‌های کوچکتر تقسیم می‌کنم', isCorrect: false},
//             {answerText: 'به دنبال راه‌حل‌های سریع هستم', isCorrect: true},
//             {answerText: 'از مسائل اجتناب می‌کنم', isCorrect: false},
//             {answerText: 'به دیگران برای حل مسائل تکیه می‌کنم', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'نظرتان درباره همکاری با دیگران در پروژه‌ها چیست؟',
//         answerOptions: [
//             {answerText: 'از کار تیمی و همکاری لذت می‌برم', isCorrect: false},
//             {answerText: 'ترجیح می‌دهم به تنهایی کار کنم', isCorrect: true},
//             {answerText: 'آن را چالش‌برانگیز می‌دانم', isCorrect: false},
//             {answerText: 'از همکاری اجتناب می‌کنم', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'نظرتان درباره یادگیری فناوری‌ها و ابزارهای جدید چیست؟',
//         answerOptions: [
//             {answerText: 'هیجان‌زده و مشتاق یادگیری هستم', isCorrect: false},
//             {answerText: 'فقط در صورت لزوم یاد می‌گیرم', isCorrect: true},
//             {answerText: 'آن را طاقت‌فرسا می‌دانم', isCorrect: false},
//             {answerText: 'از یادگیری فناوری‌های جدید اجتناب می‌کنم', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'رویکرد شما به رفع مشکلات فنی چیست؟',
//         answerOptions: [
//             {answerText: 'تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم', isCorrect: false},
//             {answerText: 'بلافاصله درخواست کمک می‌کنم', isCorrect: true},
//             {answerText: 'مشکلات را نادیده می‌گیرم', isCorrect: false},
//             {answerText: 'ناامید می‌شوم و تسلیم می‌شوم', isCorrect: false},
//         ],
//     },
//     {
//         questionText: 'نظر شما در مورد یادگیری فناوری‌ها و ابزارهای جدید چیست؟',
//         answerOptions: [
//             {answerText: 'من هیجان‌زده و مشتاق یادگیری هستم', isCorrect: false},
//             {answerText: 'فقط در صورت لزوم یاد می‌گیرم', isCorrect: true},
//             {answerText: 'آن را طاقت‌فرسا می‌دانم', isCorrect: false},
//             {answerText: 'از یادگیری فناوری‌های جدید اجتناب می‌کنم', isCorrect: false},
//         ],
//     },
// ];

const shuffleArray = (array) => {
    // eslint-disable-next-line no-plusplus
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        // eslint-disable-next-line no-param-reassign
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const Question = () => {

    const {name} = about;
    const {titled, namepersion, lastname, prefix, suffix} = aboutpersion;

    const [{themeName}] = useContext(ThemeContext)
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showScore, setShowScore] = useState(false);
    const [showIntro, setShowIntro] = useState(true);
    const [history, setHistory] = useState([]);
    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [timer, setTimer] = useState(10); // تنظیم زمان به ۱۰ ثانیه برای هر سوال

    useEffect(() => {
        setShuffledOptions(shuffleArray([...questions[currentQuestion].options]));
    }, [currentQuestion]);

    const handleStartQuiz = () => {
        setShowIntro(false);
        setTimer(10); // ریست کردن تایمر به ۱۰ ثانیه
    };

    const handleOptionChange = (e) => {
        const selected = e.target.value;
        setSelectedOption(selected);
        setTimeout(() => {
            if (selected === questions[currentQuestion].answer) {
                setScore(score + 1);
            }
            setHistory([...history, {question: currentQuestion, answer: selected}]);
            setSelectedOption(null);
            if (currentQuestion < questions.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
            } else {
                setShowScore(true);
            }
        }, 500); // Delay for better user experience
    };

    const handleNextQuestion = () => {
        if (selectedOption === questions[currentQuestion].answer) {
            setScore(score + 1);
        }
        setHistory([...history, {question: currentQuestion, answer: selectedOption}]);
        setSelectedOption(null);
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowScore(true);
        }
    };

    const handleRestart = () => {
        setCurrentQuestion(0);
        setScore(0);
        setSelectedOption(null);
        setShowScore(false);
        setHistory([]);
        setShowIntro(true);
    };

    const handlePreviousQuestion = () => {
        if (history.length > 0) {
            const lastEntry = history.pop();
            setCurrentQuestion(lastEntry.question);
            setSelectedOption(lastEntry.answer);
            setHistory(history);
        }
    };

    const getScoreLevel = () => {
        const percentage = (score / questions.length) * 100;
        if (percentage === 100) return {level: "عالی", color: "green"};
        if (percentage >= 75) return {level: "خیلی خوب", color: "blue"};
        if (percentage >= 50) return {level: "متوسط", color: "orange"};
        return {level: "ضعیف", color: "red"};
    };

    const {level, color} = getScoreLevel();

    // eslint-disable-next-line no-shadow
    // const getLevel = (score) => {
    //     if (score >= 100) return {level: "عالی", color: "green"};
    //     if (score >= 75) return {level: "خیلی خوب", color: "blue"};
    //     if (score >= 50) return {level: "متوسط", color: "orange"};
    //     return {level: "ضعیف", color: "red"};
    // };

    return (
        <div id='top' className={`${themeName} app`}>
            <HeaderPage/>
            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>
                <div>
                    <div/>
                    <div>
                        <br/>
                        <Helmet>
                            <title>کوییز برنامه‌نویسی</title>
                        </Helmet>
                        <Title level={2} className="sogand" style={{direction: 'rtl', textAlign: 'center'}}>کوییز
                            برنامه‌نویسی</Title>
                        <br/>
                    </div>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {showIntro ? (
                        <Card>
                            <div style={{direction: 'rtl', textAlign: 'center'}}>
                                <Title level={3} className="bNazanintdr">نکات
                                    قبل از شروع کوییز</Title>
                                <p className="bNazanintdr">لطفاً قبل از شروع کوییز به نکات زیر
                                    توجه کنید:</p>
                                <ul className="bNazanintdr" style={{textAlign: 'right', listStyleType: 'disc'}}>
                                    <li>هر سوال چهار گزینه دارد و تنها یک گزینه صحیح است.</li>
                                    {/* <li>زمان پاسخگویی به هر سوال محدود است.</li> */}
                                    <li>پس از اتمام کوییز، نتیجه و سطح شما نمایش داده خواهد شد.</li>
                                </ul>
                                <Button type="primary" onClick={handleStartQuiz} className="bNazanintdr">شروع
                                    کوییز</Button>
                            </div>
                        </Card>
                    ) : showScore ? (

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}>
                            <Card title="نتایج کویز" style={{width: 300}} className="vazir">
                                <div className="bNazanintdr">
                                    <Statistic title="تعداد سوالات" value={questions.length}
                                               valueStyle={{fontSize: '20px', fontFamily: 'bNazanin'}}/>
                                    <Statistic title="تعداد جواب‌های درست" value={score}
                                               valueStyle={{fontSize: '20px', fontFamily: 'bNazanin'}}/>
                                    <Statistic title="امتیاز کلی" value={score.toFixed(2)} suffix="%"
                                               valueStyle={{fontSize: '20px', fontFamily: 'bNazanin'}}/>
                                    <Statistic title="سطح" value={level}
                                               valueStyle={{color, fontSize: '20px', fontFamily: 'bNazanin'}}/>
                                </div>
                                <br/>
                                <Progress percent={score} status="active"/>
                                <br/>
                                <br/>
                                <Button type="primary" onClick={handleRestart}
                                        className="bNazanintdr">ریستارت</Button>
                            </Card>
                        </div>
                    ) : (
                        <>
                            <div style={{direction: 'rtl', textAlign: 'right'}}>
                                <Title level={3}
                                       className="bNazanint">سوال {questions[currentQuestion].id} از {questions.length}</Title>
                                <Title level={4}
                                       className="bNazanintdrb">{questions[currentQuestion].question}</Title>
                                <Radio.Group onChange={handleOptionChange} value={selectedOption}>
                                    <Space direction="vertical">
                                        {/* eslint-disable-next-line no-shadow */}
                                        {shuffledOptions.map((option) => (
                                            <Radio className="bNazanintdr" key={option}
                                                   value={option}>{option}</Radio>
                                        ))}
                                    </Space>
                                </Radio.Group>
                                <div>
                                    <br/>
                                    <Space>
                                        <Button onClick={handlePreviousQuestion} disabled={history.length === 0}
                                                className="bNazanintdr">
                                            بازگشت به گزینه قبلی
                                        </Button>
                                    </Space>
                                </div>
                            </div>
                        </>
                    )
                    }
                </div>

                <br/>
                <br/>
                <br/>
                <br/>

                {/* <div> */}
                {/*     {name && ( */}
                {/*         <h2 style={{fontSize: 25}}> */}
                {/*             /!* Dr. <span className='about__name'>{name}</span> *!/ */}
                {/*             /!* <Avatar *!/ */}
                {/*             /!*     src={esmaeil} *!/ */}
                {/*             /!*     shape='circle' *!/ */}
                {/*             /!*     size='large' *!/ */}
                {/*             /!*     // sx={{width: 200, height: 200}} *!/ */}
                {/*             /!* /> *!/ */}

                {/*             /!* <img src={esmaeil} alt={titled}/> *!/ */}
                {/*             <span className='about__name nastaliq'>{prefix}</span> */}
                {/*             <span className='about__name shekasteh'>{namepersion}</span> */}
                {/*             <span className='about__name shekastehlastname'>{lastname}</span> */}
                {/*             /!* <span className='about__name dimaShekasteh'>{suffix}</span> *!/ */}
                {/*             <br/> */}
                {/*             <span className='about__name__title bNazanint'>{titled}</span> */}
                {/*         </h2> */}
                {/*     )} */}
                {/* </div> */}

                {/* <div style={{direction: 'rtl', textAlign: 'right', padding: '20px'}}> */}
                {/*     <Helmet> */}
                {/*         <title>کوییز برنامه‌نویسی</title> */}
                {/*     </Helmet> */}
                {/*     <Title level={2}>کوییز برنامه‌نویسی</Title> */}
                {/*     {showScore ? ( */}
                {/*         <div> */}
                {/*             <Title level={3}>نمره شما: {score} از {questions.length}</Title> */}
                {/*             <Title level={4}>سطح: {getScoreLevel()}</Title> */}
                {/*             <Button type="primary" onClick={handleRestart}>ریستارت</Button> */}
                {/*         </div> */}
                {/*     ) : ( */}
                {/*         <div> */}
                {/*             <Title level={4}>{questions[currentQuestion].question}</Title> */}
                {/*             <Radio.Group onChange={handleOptionChange} value={selectedOption}> */}
                {/*                 <Space direction="vertical"> */}
                {/*                     /!* eslint-disable-next-line no-shadow *!/ */}
                {/*                     {shuffleArray(questions[currentQuestion].options).map((option, index) => ( */}
                {/*                         // eslint-disable-next-line react/no-array-index-key */}
                {/*                         <Radio key={index} value={option}>{option}</Radio> */}
                {/*                     ))} */}
                {/*                 </Space> */}
                {/*             </Radio.Group> */}
                {/*         </div> */}
                {/*     )} */}
                {/* </div> */}


                {/* <div className="bNazanintd" style={{padding: '50px', direction: 'rtl', textAlign: 'inherit'}}> */}
                {/* <div> */}
                {/*     <Helmet> */}
                {/*         <title>کوییز برنامه‌نویسی</title> */}
                {/*     </Helmet> */}
                {/*     <Title className="bTitr" level={2} style={{textAlign: 'center'}}>کوییز برنامه‌نویسی</Title> */}
                {/*     {showScore ? ( */}
                {/*         <div style={{direction: 'rtl', textAlign: 'center', padding: '20px'}}> */}
                {/*             <Title level={3} className="vazirlightd">نمره شما: {score} از {questions.length}</Title> */}
                {/*             /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
                {/*             <Title level={4} className="vazirlightd">سطح: <a */}
                {/*                 className="bTitr">{getScoreLevel()}</a></Title> */}
                {/*             <div> */}
                {/*                 <br/> */}
                {/*                 <Button type="primary" onClick={handleRestart} className="bNazanintdr">ریستارت</Button> */}
                {/*             </div> */}

                {/*         </div> */}
                {/*     ) : ( */}
                {/*         <div style={{direction: 'rtl', textAlign: 'right', padding: '20px'}}> */}
                {/*             <Title className="vazirlightd" level={4}>{questions[currentQuestion].question}</Title> */}
                {/*             <Radio.Group onChange={handleOptionChange} value={selectedOption}> */}
                {/*                 <Space direction="vertical"> */}
                {/*                     /!* eslint-disable-next-line no-shadow *!/ */}
                {/*                     {shuffleArray(questions[currentQuestion].options).map((option, index) => ( */}
                {/*                         // eslint-disable-next-line react/no-array-index-key */}
                {/*                         <Radio className="bNazanintdr" key={index} value={option}>{option}</Radio> */}
                {/*                     ))} */}
                {/*                 </Space> */}
                {/*             </Radio.Group> */}
                {/*             <div> */}
                {/*                 <Button type="primary" onClick={handleNextQuestion} style={{marginTop: '20px'}} */}
                {/*                         className="bNazanintdr"> */}
                {/*                     سوال بعدی */}
                {/*                 </Button> */}
                {/*             </div> */}
                {/*         </div> */}
                {/*     )} */}
                {/* </div> */}


                {/* {showScore ? ( */}
                {/*     <div className='score-section'> */}
                {/*         <Title level={2}>شما {score} از {questions.length} نمره گرفتید</Title> */}
                {/*         <Button type="primary" onClick={handleRestart} style={{marginTop: '20px'}}> */}
                {/*             ریستارت */}
                {/*         </Button> */}
                {/*     </div> */}
                {/* ) : ( */}
                {/*     <> */}
                {/*         <div className='question-section'> */}
                {/*             <Title level={3}>سوال {currentQuestion + 1}/{questions.length}</Title> */}
                {/*             <Title level={4}>{questions[currentQuestion].questionText}</Title> */}
                {/*         </div> */}
                {/*         <div className='answer-section'> */}
                {/*             <Radio.Group onChange={(e) => setSelectedAnswer(e.target.value)} */}
                {/*                          value={selectedAnswer}> */}
                {/*                 <Space direction="vertical"> */}
                {/*                     {questions[currentQuestion].answerOptions.map((answerOption, index) => ( */}
                {/*                         // eslint-disable-next-line react/no-array-index-key */}
                {/*                         <Radio key={index} value={index}>{answerOption.answerText}</Radio> */}
                {/*                     ))} */}
                {/*                 </Space> */}
                {/*             </Radio.Group> */}
                {/*         </div> */}
                {/*         <Button type="primary" onClick={handleAnswerOptionClick} style={{marginTop: '20px'}}> */}
                {/*             بعدی */}
                {/*         </Button> */}
                {/*     </> */}
                {/* )} */}
                {/*  </div> */}

                <Contact/>
            </main>

            <ScrollToTop/>
            <Footer/>
        </div>
    )

}

export default Question;