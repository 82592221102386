import React, {useContext} from "react";
import {LoginOutlined} from "@ant-design/icons/lib/icons";
import Footer from "../../Footer/Footer";
import {ThemeContext} from "../../../contexts/theme";
import HeaderPage from "../../Header/HeaderPage";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

const Automaton = () => {

    const [{themeName}] = useContext(ThemeContext)
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    return (
        <div id='top' className={`${themeName} app`}>
            <HeaderPage/>
            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>

                <div className='about center'>
                    <h2 style={{fontSize: 25}}>
                        <span className='about__name nastaliq'>نظریه </span>
                        <span className='about__name shekastehlastname'> زبان ها و</span>
                        <span className='about__name shekastehlastname'>ماشین ها</span>
                    </h2>
                    <br/>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img src="https://m.media-amazon.com/images/I/81B2Qqs20aL._SL1500_.jpg"
                         style={{
                             display: "block",
                             margin: "0 auto",
                             height: '330px',
                             width: '0 auto',
                             border: '50px'
                         }}/>
                    <br/>
                    <span className='about__name__title bNazanint2'>مدرس: دکتر اسماعیل صادقی</span>
                    <p className='about__desc bNazaninF'>نظریه زبان‌ها و ماشین‌ها یکی از دروس اصلی در حوزه کامپیوتر است
                        که در آن به سه موضوع زبان، گرامر و ماشین، پرداخته می‌شود. این درس پیش‌نیاز درس طراحی کامپایلر
                        است. با یادگیری زبان‌ها و گرامرها، نحوه کار کامپایلر و همچنین طراحی زبان‌های برنامه‌سازی را
                        متوجه خواهید شد</p>

                    <br/>

                    <ul className='project__stack lalezarLink'>
                        <li className='project__stack-item'>
                            جزئیات
                        </li>
                    </ul>

                    <a
                        href="https://drive.google.com/drive/folders/1VgnDs33kI2dhmxQEALSQsopyNFTRyomY?usp=sharing"
                        aria-label='Document'
                        className='link link--icon'
                    >
                        <LoginOutlined style={{fontSize: '150%'}}/>
                    </a>

                </div>
                {/* <Contact/> */}
            </main>

            <ScrollToTop/>
            <Footer/>
        </div>
    )
}

export default Automaton