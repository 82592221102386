import uniqid from 'uniqid'
import {skills, skillsSrc} from '../../portfolio'
import './Skills.css'
import java from '../../contexts/img/java.svg';
import html from '../../contexts/img/file_type_html_icon_130541.svg';
import solidity from '../../contexts/img/light-solidity-svgrepo-com.svg';
import nodejs from '../../contexts/img/node-js-icon.svg';
import bitcoin from '../../contexts/img/bitcoin-svgrepo-com (1).svg';
import android from '../../contexts/img/android-robot-bot-icon.svg';
import python from '../../contexts/img/python-programming-language-icon.svg';
import flutter from '../../contexts/img/flutter_logo.svg';
import mongodb from '../../contexts/img/icons8-mongodb.svg';
import sqlserver from '../../contexts/img/icons8-microsoft-sql-server.svg';
import postgresql from '../../contexts/img/postgresql-icon.svg';
import redis from '../../contexts/img/icons8-redis.svg';
import oracle from '../../contexts/img/icons8-oracle-logo.svg';
import react from '../../contexts/img/react-js-icon.svg';
import css from '../../contexts/img/css-icon.svg';
import javascript from '../../contexts/img/javascript-programming-language-icon.svg';
import cassandra from '../../contexts/img/apache_cassandra_logo_icon_170572.svg';
import docker from '../../contexts/img/icons8-docker (1).svg';
import kubernetes from '../../contexts/img/icons8-kubernetes.svg';
import linux from '../../contexts/img/linux_original_logo_icon_146433.svg';
import kafka from '../../contexts/img/kafka-svgrepo-com.svg';
import jenkins from '../../contexts/img/jenkins-svgrepo-com.svg';
import spring from '../../contexts/img/spring-icon-svgrepo-com.svg';
import hibernate from '../../contexts/img/hibernate-svgrepo-com.svg';
import swagger from '../../contexts/img/swagger-svgrepo-com.svg';
import kotlin from '../../contexts/img/kotlin-svgrepo-com.svg';
import dotnet from '../../contexts/img/dotnet-svgrepo-com.svg';
import firestore from '../../contexts/img/icons8-cloud-firestore.svg';
import mysql from '../../contexts/img/icons8-mysql-logo.svg';
import prometheus from '../../contexts/img/prometheus-svgrepo-com.svg';
import atlassian from '../../contexts/img/atlassian-svgrepo-com.svg';
import grafana from '../../contexts/img/grafana-svgrepo-com.svg';
import kibana from '../../contexts/img/kibana-svgrepo-com.svg';
import elastic from '../../contexts/img/elastic-svgrepo-com.svg';
import camel from '../../contexts/img/apache-camel-svgrepo-com.svg';
import hadoop from '../../contexts/img/hadoop-svgrepo-com.svg';
import apache from '../../contexts/img/apache-svgrepo-com.svg';
import ubuntu from '../../contexts/img/ubuntu-svgrepo-com.svg';
import php from '../../contexts/img/php-svgrepo-com.svg';
import nginx from '../../contexts/img/icons8-nginx.svg';

const Skills = () => {
    // if (!skills.length) return null
    if (!skillsSrc.length) return null

    return (
        <section className='section skills' id='skills'>
            <h2 className='section__title sogand' style={{fontSize: 30}} >مهارت ها</h2>
            {/* <ul className='skills__list'> */}
            {/*     {skills.map((skill) => ( */}
            {/*         <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}> */}
            {/*             /!* eslint-disable-next-line jsx-a11y/alt-text *!/ */}
            {/*             <img src={java} width="50px" alt=""  style={{display:"block", margin:'0 auto'}}'/> */}
            {/*             {skill} */}
            {/*         </li> */}
            {/*     ))} */}
            {/* </ul> */}
            <ul className='skills__list'>
                {/* {skillsSrc.map((skill) => ( */}
                {/*     <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}> */}
                {/*         <img src={java}  width="50px" alt=""  style={{display:"block", margin:'0 auto'}}'/> */}
                {/*          {skill.name} */}
                {/*      </li> */}
                {/* ))} */}
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={java}  width="50px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={flutter}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={android}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={html}  width="50px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={css}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={react}  width="35px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={postgresql}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={solidity}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={python}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={nodejs}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={bitcoin}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={kubernetes}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={mongodb}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={redis}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={docker}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={linux}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={javascript}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={kotlin}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={cassandra}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={jenkins}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={spring}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={hibernate}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={swagger}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={dotnet}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={firestore}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={prometheus}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={atlassian}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={grafana}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={kibana}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={elastic}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={camel}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={apache}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={hadoop}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={php}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={nginx}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={ubuntu}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={mysql}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={sqlserver}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={kafka}  width="45px" alt="" className='image'/>
                </li>
                <li key={uniqid()} className='skills__list-item btn btn--plain' style={{textAlign:"center"}}>
                    <img src={oracle}  width="45px" alt="" className='image'/>
                </li>
            </ul>

        </section>
    )
}

export default Skills
