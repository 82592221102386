import Icon from "antd/es/icon";
import {Avatar, Col, Image, Row, Space} from "antd";
import Typewriter from "typewriter-effect";
import {
    GithubFilled,
    InstagramFilled,
    LinkedinFilled,
    TwitterOutlined,
    FacebookOutlined,
} from '@ant-design/icons';
import {about, aboutpersion, contact} from '../../portfolio'
import './About.css'
import TelSVG from '../../contexts/img/telegram.svg';
import esmaeil from "./esmail.png";

// eslint-disable-next-line react/jsx-props-no-spreading
const TelIcon = props => <Icon component={TelSVG} {...props} />;

const About = () => {
    const {name, role, description, resume, social, animated,aptitude} = about
    const {titled, namepersion, lastname, prefix, suffix} = aboutpersion
    const state = {date: new Date()}
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    // function onClick() {
    //
    // }
    const path = process.env.PUBLIC_URL;

    return (
        <div className='about center'>

            {/* <div style={{marginBottom: 50}}> */}
            {/*     <Space direction="vertical" size={16}> */}
            {/*         <Space wrap size={16}> */}
            {/*             <Avatar size={64} icon={<Image */}
            {/*                 width={200} */}
            {/*                 src="https://photos.google.com/share/AF1QipOmXJG91w3C7pF4R-GbOVNvydZhv_2ULTTCR0VPfIZXHnXWpUZHUM0gUeOtyrtXQQ/photo/AF1QipNvwRLxg_k2eZ9udkXYj1HQUggm7Ay7cX4LIL8?key=U1JIYkZTZFc0X3RrYjh0UU9pQ0d2MmpqRjUxQ2Zn" */}
            {/*                 preview={{ */}
            {/*                     src: 'https://photos.google.com/share/AF1QipOmXJG91w3C7pF4R-GbOVNvydZhv_2ULTTCR0VPfIZXHnXWpUZHUM0gUeOtyrtXQQ/photo/AF1QipNvwRLxg_k2eZ9udkXYj1HQUggm7Ay7cX4LIL8?key=U1JIYkZTZFc0X3RrYjh0UU9pQ0d2MmpqRjUxQ2Zn1', */}
            {/*                 }} */}
            {/*             />} /> */}
            {/*         </Space> */}
            {/*     </Space> */}
            {/* </div> */}

            {/* <div> */}
            {/*     <img */}
            {/*         // src="https://bobbyhadz.com/images/blog/react-usestate-dynamic-key/thumbnail.webp" */}
            {/*         src="https://photos.google.com/album/AF1QipOHbRYmMhIU4EVfh7hw75_2mjZu9UuGmq3__Bs" */}
            {/*         alt="example" */}
            {/*     /> */}
            {/* </div> */}

            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt,jsx-a11y/alt-text */}
            {/* <img src={`${process.env.PUBLIC_URL}/public/esmail.png`} /> */}
            {/* eslint-disable-next-line global-require,import/no-unresolved */}
            {/* <div style={{width:100 , height:200}}> */}
            {/*     <Avatar */}
            {/*         src={esmaeil} */}
            {/*         shape='circle' */}
            {/*         size='large' */}
            {/*         // sx={{width: 200, height: 200}} */}
            {/*     /> */}

            {/* </div> */}

            {name && (
                <h2 style={{fontSize: 25}}>
                    {/* Dr. <span className='about__name'>{name}</span> */}
                    {/* <Avatar */}
                    {/*     src={esmaeil} */}
                    {/*     shape='circle' */}
                    {/*     size='large' */}
                    {/*     // sx={{width: 200, height: 200}} */}
                    {/* /> */}

                    {/* <img src={esmaeil} alt={titled}/> */}
                    <span className='about__name nastaliq'>{prefix}</span>
                    <span className='about__name shekastehnamepersion'>{namepersion}</span>
                    <span className='about__name shekastehlastname'>{lastname}</span>
                    {/* <span className='about__name dimaShekasteh'>{suffix}</span> */}
                    <br/>
                    <span className='about__name__title bNazanint'>{titled}</span>
                </h2>
            )}

            {/* <CarouselFerris/> */}


            {/* <CarouselComponent/> */}
            {role && <h4 className='about__role' style={{fontSize: 12}}>
                <Typewriter
                    options={{
                        strings: [
                            animated.first,
                            animated.second,
                            animated.third,
                        ],
                        autoStart: true,
                        deleteSpeed: 20,
                        pauseFor: 10000,
                        loop: true,

                    }}
                />
            </h4>}
            {/* <p>  {d.toLocaleDateString("fa-IR", options)}</p> */}
            <p className='about__desc bNazaninF'>{description}</p>

            <div className='about__contact center'>
                {resume && (
                    <a href={resume}>
                                <span type='button' className='btn btn--outline vazirlight'>
                                  رزومه کاری
                                </span>
                    </a>
                )}

                {aptitude && (
                    <a href={aptitude}>
                                <span type='button' className='btn btn--outline vazirlight'>

                                    استعدادیابی
                                    {/* کوییز برنامه‌نویسی */}
                                </span>
                    </a>
                )}

                {social && (
                    <>
                        {/* {social.yahoo && ( */}
                        {/*     <a */}
                        {/*         href={`mailto:${contact.yahoo}`} */}
                        {/*         aria-label='yahoo' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <YahooOutlined style={{fontSize: '180%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {/* {social.yahoo && ( */}
                        {/*     <a */}
                        {/*         href={`mailto:${contact.yahoo}`} */}
                        {/*         aria-label='yahoo' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <GoogleCircleFilled style={{fontSize: '180%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}
                        {/* {social.yahoo && ( */}
                        {/*     <a */}
                        {/*         href={`mailto:${contact.yahoo}`} */}
                        {/*         aria-label='yahoo' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <GoogleOutlined style={{fontSize: '180%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}


                        {/* {social.telegram && ( */}
                        {/*     <a */}
                        {/*         href={social.telegram} */}
                        {/*         aria-label='telegram' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*        /!* <img src={TelIcon} style={{width: 25, height: 34}} alt=""/> *!/ */}
                        {/*     <TelIcon style={{fontSize: '150%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}


                        {/* {social.skype && ( */}
                        {/*     <a */}
                        {/*         href={social.skype} */}
                        {/*         aria-label='skype' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <SkypeFilled style={{fontSize: '170%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {/* {social.whatsApp && ( */}
                        {/*     <a */}
                        {/*         href={social.whatsApp} */}
                        {/*         aria-label='whatsApp' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <WhatsAppOutlined style={{fontSize: '150%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {/* {social.google && ( */}
                        {/*     <a */}
                        {/*         href={`mailto:${contact.email}`} */}
                        {/*         aria-label='google' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         /!* eslint-disable-next-line react/jsx-no-undef *!/ */}
                        {/*         <GoogleCircleFilled style={{fontSize: '170%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {social.github && (
                            <a
                                href={social.github}
                                aria-label='github'
                                className='link link--icon'
                            >
                                <GithubFilled style={{fontSize: '150%'}}/>
                            </a>
                        )}


                        {/* {social.apple && ( */}
                        {/*     <a */}
                        {/*         href={social.apple} */}
                        {/*         aria-label='apple' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <AppleOutlined style={{fontSize: '180%', marginBottom: 9}}/> */}
                        {/*     </a> */}
                        {/* )} */}


                        {social.linkedin && (
                            <a
                                href={social.linkedin}
                                aria-label='linkedin'
                                className='link link--icon'
                            >
                                <LinkedinFilled style={{fontSize: '150%'}}/>
                            </a>
                        )}

                        {social.facebook && (
                            <a
                                href={social.facebook}
                                aria-label='facebook'
                                className='link link--icon'
                            >
                                <FacebookOutlined style={{fontSize: '150%'}}/>
                            </a>
                        )}


                        {social.instagram && (
                            <a
                                href={social.instagram}
                                aria-label='instagram'
                                className='link link--icon'
                            >
                                <InstagramFilled style={{fontSize: '150%'}}/>
                            </a>
                        )}

                        {social.twitter && (
                            <a
                                href={social.twitter}
                                aria-label='twitter'
                                className='link link--icon'
                            >
                                <TwitterOutlined style={{fontSize: '150%'}}/>
                            </a>
                        )}

                    </>
                )}


            </div>

            <div className='about center vazirlightd'>
                <p hidden> {d.getDate() + 1}</p>
                {/* <p> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p> */}
                {/* <p>  {d.toLocaleDateString("fa-IR")}</p> */}
            </div>

            {/* <div className='about__contact center'> */}
            {/*      */}
            {/* </div> */}
        </div>
    )
}

export default About
