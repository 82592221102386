import {
    HomeOutlined
} from '@ant-design/icons';
import {header} from '../../portfolio'
import './Header.css'
import Navbar from "../Navbar/Navbar";

const Header = () => {
    const {homepage, title} = header

    return (
        <header className='header center'>
            <h4>
                {homepage ? (
                    <a href={homepage} className='link'>
                        {/* {title} */}
                        <HomeOutlined />
                    </a>
                ) : (
                    title
                )}
            </h4>
            <Navbar/>
        </header>
    )
}

export default Header
