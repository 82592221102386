import {render} from 'react-dom'
import App from './App'
import {ThemeProvider} from './contexts/theme'
import './index.css'
// eslint-disable-next-line import/order
import {BrowserRouter as Router} from "react-router-dom";


render(
  // <ThemeProvider>
    <App />,
  // </ThemeProvider>,
  document.getElementById('root')
)
