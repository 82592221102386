import './Footer.css'
import {Space} from "antd";
import Font, {Text} from 'react-font'

const Footer = () => (
    <footer className='footer'>
        {/*   <p style={{fontSize:10}} className='phone__no'>+98 21 66353207</p> */}
        {/* <a */}
        {/*   href='https://www.instagram.com/javatar2' */}
        {/*   className='link footer__link' */}
        {/*   style={{fontSize:13}} */}
        {/* > */}
        {/*   Created By JavaTar */}
        {/* </a> */}
        <div className='copyr'>
            <p className='underline'><span>PRIVACY POLICY</span> | <span>TERMS OF USE</span></p>
            <p className='underline'><span>YOUR PRIVACY RIGHTS</span> | <span>ONLINE PRIVACY POLICY</span></p>
        </div>
        <p className='copyrb'>© 1998 - 2020 JAVATAR | +98 21 66353207</p>

    </footer>
)

export default Footer
