import {faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    MenuOutlined,
    CloseOutlined,
    GithubFilled,
    LinkedinFilled,
    FacebookOutlined,
    InstagramFilled, TwitterOutlined
} from '@ant-design/icons';
import {useContext, useState} from 'react'
import {ThemeContext} from '../../contexts/theme'
import {projects, skills, contact, about} from '../../portfolio'
import './Navbar.css'

const NavbarPage = () => {
    const [{themeName, toggleTheme}] = useContext(ThemeContext)
    const [showNavList, setShowNavList] = useState(false)
    const {name, role, description, resume, social, animated} = about

    const toggleNavList = () => setShowNavList(!showNavList)

    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    return (
        <nav className='center nav'>
            <ul
                style={{display: showNavList ? 'flex' : null}}
                className='nav__list'
            >
                <>
                </>
            </ul>

            {/* <div className='about__contact center'> */}

            {/*     /!*     {resume && ( *!/ */}
            {/*     /!*         <a href={resume}> *!/ */}
            {/*     /!* <span type='button' className='btn btn--outline vazirlight'> *!/ */}
            {/*     /!*   رزومه کاری *!/ */}
            {/*     /!* </span> *!/ */}
            {/*     /!*         </a> *!/ */}
            {/*     /!*     )} *!/ */}

            {/*     {social && ( */}
            {/*         <> */}

            {/*             {social.github && ( */}
            {/*                 <a */}
            {/*                     href={social.github} */}
            {/*                     aria-label='github' */}
            {/*                     className='link link--icon' */}
            {/*                 > */}
            {/*                     <GithubFilled style={{fontSize: '150%'}}/> */}
            {/*                 </a> */}
            {/*             )} */}


            {/*             {social.linkedin && ( */}
            {/*                 <a */}
            {/*                     href={social.linkedin} */}
            {/*                     aria-label='linkedin' */}
            {/*                     className='link link--icon' */}
            {/*                 > */}
            {/*                     <LinkedinFilled style={{fontSize: '150%'}}/> */}
            {/*                 </a> */}
            {/*             )} */}

            {/*             {social.facebook && ( */}
            {/*                 <a */}
            {/*                     href={social.facebook} */}
            {/*                     aria-label='facebook' */}
            {/*                     className='link link--icon' */}
            {/*                 > */}
            {/*                     <FacebookOutlined style={{fontSize: '150%'}}/> */}
            {/*                 </a> */}
            {/*             )} */}


            {/*             {social.instagram && ( */}
            {/*                 <a */}
            {/*                     href={social.instagram} */}
            {/*                     aria-label='instagram' */}
            {/*                     className='link link--icon' */}
            {/*                 > */}
            {/*                     <InstagramFilled style={{fontSize: '150%'}}/> */}
            {/*                 </a> */}
            {/*             )} */}

            {/*             {social.twitter && ( */}
            {/*                 <a */}
            {/*                     href={social.twitter} */}
            {/*                     aria-label='twitter' */}
            {/*                     className='link link--icon' */}
            {/*                 > */}
            {/*                     <TwitterOutlined style={{fontSize: '150%'}}/> */}
            {/*                 </a> */}
            {/*             )} */}

            {/*         </> */}
            {/*     )} */}

            {/* </div> */}

            <button
                type='button'
                onClick={toggleTheme}
                className='btn btn--icon nav__theme'
                aria-label='toggle theme'
            >
                {/* {themeName === 'dark' ? <EyeOutlined   style={{fontSize: '180%'}}/> : <StarOutlined style={{fontSize: '180%'}}/>} */}
                {themeName === 'dark' ? <FontAwesomeIcon icon={faSun} style={{fontSize: '150%'}}/> :
                    <FontAwesomeIcon icon={faMoon} style={{fontSize: '150%'}}/>}
            </button>

            <button
                type='button'
                onClick={toggleNavList}
                className='btn btn--icon nav__hamburger'
                aria-label='toggle navigation'
            >
                {showNavList ? <CloseOutlined/> : <MenuOutlined/>}
            </button>
        </nav>
    )
}

export default NavbarPage
