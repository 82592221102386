import React, {useContext} from "react";
import {Table, Tag} from "antd";
import Footer from "../../Footer/Footer";
import {ThemeContext} from "../../../contexts/theme";
import HeaderPage from "../../Header/HeaderPage";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import "./Java.css"

const columns = [
    // Table.EXPAND_COLUMN,
    {
        title: <span className="bNazanint2" style={{display: 'block', textAlign: 'center'}}>سطح</span>,
        key: 'tags',
        dataIndex: 'tags',
        render: (_, {tags}) => (
            <>
                {tags.map((tag) => {
                    let color = tag.length > 6 ? 'geekblue' : 'green';
                    if (tag === 'Senior ') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
        align: 'right',
        width: '15%',
    },
    {
        title: <span className="bNazanint2" style={{display: 'block', textAlign: 'center'}}>ساعت</span>,
        dataIndex: 'age',
        key: 'age',
        align: 'right',
        width: '9%',
        render: text => <span className="bNazanint">{text}</span>,
    },
    {
        title: <span className="bNazanint2" style={{display: 'block', textAlign: 'center'}}>شرح</span>,
        dataIndex: 'address',
        // eslint-disable-next-line react/no-array-index-key
        // key: 'address', render: (text) => ( <div className="bNazanint"> {text.split('\n').map((line, index) => ( <div key={index}>{line}</div> ))} </div> )
        key: 'address',
        // eslint-disable-next-line react/no-array-index-key
        render: (text) => (<div> {text.split('\n').map((line, index) => (<div className="bNazanint" key={index}
                                                                              style={{textAlign: index % 2 === 0 ? 'left' : 'right'}}>{line}</div>))} </div>),
        align: 'right',
        width: '40%',
    },
    {
        title: <span className="bNazanint2" style={{display: 'block', textAlign: 'center'}}>نام دوره</span>,
        dataIndex: 'name',
        // eslint-disable-next-line react/no-array-index-key
        key: 'name',
        align: 'right',
        width: '20%',
        // fixed: 'left',
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        // render: (text) => <a className="bNazanint">{text}</a>,
        // render: (text) => <a href={text.website} target="_blank" rel="noopener noreferrer" className="bNazanint">{text}</a>,

        render: (text, record) => <a href={record.link} target="_blank" rel="noopener noreferrer"
                                     className="bNazanintP">{text}</a>
        // eslint-disable-next-line react/no-array-index-key
    },

    // {
    //     title: 'Action',
    //     key: 'action',
    //     render: (_, record) => (
    //         <Space size="middle">
    //             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    //             <a>Invite {record.name}</a>
    //             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    //             <a>Delete</a>
    //         </Space>
    //     ),
    // },
];
const data = [
    {
        key: '1',
        name: 'Core Java',
        age: 30,
        address: 'Introductory Java Programming \n دوره مقدماتی برنامه نویسی جاوا',
        tags: ['Middle ', 'Junior'],
        link: 'https://drive.google.com/file/d/14vr6G6PuZi-a6XUNDelJX1HcPkmgR0mO/view?usp=sharing'
    },
    {
        key: '2',
        name: 'Java SE',
        age: 40,
        address: 'Java SE  \n دوره برنامه نویسی استاندارد جاوا',
        tags: ['Middle ', 'Junior'],
        link: 'https://drive.google.com/file/d/14vr6G6PuZi-a6XUNDelJX1HcPkmgR0mO/view?usp=sharing'
    },
    {
        key: '3',
        name: 'Java EE',
        age: 50,
        address: 'Jakarta EE | The Eclipse Foundation \n دوره برنامه نویسی جاوا تحت وب (جاکارتا)',
        tags: ['Senior ', 'Middle '],
        link: 'https://drive.google.com/file/d/1Fcrnf0BxO9DfKApNeu6amaV-iYLQjiGv/view?usp=sharing'

    },
    {
        key: '4',
        name: 'Security',
        // name: 'Detail X\nDetail Y\nDetail Z', // استفاده از \n برای نمایش چند سطر
        age: 50,
        address: 'Secure Coding in Java \n کد نویسی امن در جاوا',
        tags: ['Senior ', 'Middle '],
        link: 'https://drive.google.com/file/d/1PmjJ76E9nluFvCWa4mc_eFCB1IdTDXdK/view?usp=sharing'
    },
    // {
    //     key: '4',
    //     name: 'ME  جاوا',
    //     age: 100,
    //     address: 'دوره آموزش برنامه نویسی اندروید',
    //     tags: ['Middle ', 'Junior'],
    //     // description: 'ttttttttttttttttttttttttttttt'
    // },
];

const Java = () => {

    const [{themeName}] = useContext(ThemeContext)
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    return (
        <div id='top' className={`${themeName} app`}>
            <HeaderPage/>
            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>

                <div className='about center'>
                    <h2 style={{fontSize: 25}}>
                        <span className='about__name nastaliq'>آموزش </span>
                        <span className='about__name shekastehlastname'> برنامه نویسی </span>
                        <span className='about__name shekastehlastname'> جاوا</span>
                    </h2>
                    <br/>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img
                        src="https://cdn.iconscout.com/icon/free/png-256/free-java-logo-icon-download-in-svg-png-gif-file-formats--technology-social-media-company-vol-1-pack-logos-icons-3029997.png?f=webp&w=256"
                        style={{
                            display: "block",
                            margin: "0 auto",
                            height: '220px',
                            width: '0 auto',
                            border: '50px'
                        }}/>
                    <br/>
                    <h3>
                        <br/>
                        <span className='about__name shekastehJavaT'>مدرس: </span>
                        <span className='about__name nastaliqJava'>دکتر اسماعیل صادقی</span>
                    </h3>
                    <p className='about__desc des'>جاوا یک زبان برنامه نویسی سطح بالا، مبتنی بر کلاس و شی گرا است
                        که به گونه ای طراحی شده است که تا حد امکان وابستگی های پیاده سازی کمتری داشته باشد. زبان
                        برنامه‌نویسی جاوا یکی از پرکاربردترین و محبوب‌ترین زبان‌های برنامه‌نویسی در جهان است که به
                        وسیله‌ی شرکت سان مایکروسیستمز (که بعدها توسط اوراکل خریداری شد) توسعه داده شده است. جاوا برای
                        توسعه‌ی نرم‌افزارهای مختلف از جمله برنامه‌های دسکتاپ، موبایل، وب، و سیستم‌های بزرگ سازمانی به
                        کار می‌رود</p>
                    <br/>

                    <br/>
                    <h3>
                        <br/>
                        <span className='about__name nastaliqJavaT'>:لیست دوره های آموزشی</span>
                    </h3>

                    <Table
                        // className="custom-table"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        bordered
                        scroll={{x: '100%', y: '100%'}}
                        // expandable={{
                        //     expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>,
                        // }}
                    />

                </div>
                {/* <Contact/> */}
            </main>

            <ScrollToTop/>
            <Footer/>
        </div>
    )
}

export default Java