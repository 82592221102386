import React, {useContext} from "react";
import Footer from "../../Footer/Footer";
import {ThemeContext} from "../../../contexts/theme";
import HeaderPage from "../../Header/HeaderPage";

const software = () => {

    const [{themeName}] = useContext(ThemeContext)
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    return (
        <div id='top' className={`${themeName} app`}>
            <main>
                <HeaderPage/>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>
                <h1>WellCome.........................software</h1>
                <Footer/>
            </main>
        </div>
    )
}

export default software