import React from 'react';

const Map = () => (
    <div>
        <iframe
            title="Google Maps Location" // Added title for accessibility
            // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4512.608806923309!2d51.41802429614139!3d35.7431049387701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e06862496eb29%3A0xd8943e452638c102!2sTehran%20Province%2C%20Nelson%20Mandela%20Blvd%2C%20Iran!5e0!3m2!1sen!2sus!4v1728908348027!5m2!1sen!2sus"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1618.0459087987942!2d51.46509953888539!3d35.79767916864509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDQ3JzUxLjYiTiA1McKwMjcnNTkuMCJF!5e0!3m2!1sen!2s!4v1729584973865!5m2!1sen!2s"
            width="100%"
            height="300"
            style={{border: 0}} // Changed to object syntax for style
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        />
    </div>
);

export default Map;