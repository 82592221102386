import {Flex, Splitter, Typography} from 'antd';
import {
    MailOutlined,
    MailTwoTone,
    MessageOutlined,
    MobileOutlined,
    PhoneFilled,
    PhoneOutlined,
    PhoneTwoTone
} from '@ant-design/icons'
import {contact} from '../../portfolio'
import './Contact.css'
import telegram from "../../contexts/img/icons8-telegram-app.svg";

import Map from "../Map/map";


const Contact = () => {
    if (!contact.email) return null;

    return (

        <section className='contact center' id='contact'>
            <h2 className='section__title sogand' style={{fontSize: 30}}>
                تماس با ما
            </h2>
            <div className="grid-container">
                <div className="map-column">
                    <Map/>
                </div>
                <div className='mailfont'>
                    <p style={{marginBottom: 7}}>
                        <span className='mail outline'>
                            <img src={telegram} alt="Telegram" style={{width: 16, height: 16}}/>
                        </span>
                        <span>
                            <a className="link2" href="https://t.me/computer_programming_school">
                                computer programming school
                            </a>
                        </span>
                    </p>
                    <p>
                        <span className='mail outline'>
                            <MailOutlined/>
                        </span>
                        <span>
                            <a className="link2" href="mailto:esmaeilsadeghijob@gmail.com">
                                 esmaeilsadeghijob@gmail.com
                            </a>
                        </span>
                    </p>
                    <p>
                        <span className='mail outline'>
                            <PhoneOutlined/>
                        </span>
                        <span>
                            <a className="link2" href="tel:+989122273119">
                                 9122273119
                            </a>
                        </span>
                    </p>
                    <a href={`mailto:${contact.email}`}>
                        <button type='button' className='btn btn--outline vazirlight'>
                            ارسال ایمیل
                        </button>
                    </a>
                </div>


            </div>
        </section>

    );
};


// const Contact = () => {
//     if (!contact.email) return null;
//
//     return (
//         // <div className="grid-container  section1 section__title1">
//             <section className='contact center' id='contact'>
//                 <h2 className='section__title sogand' style={{fontSize: 30}}>
//                     تماس با ما
//                 </h2>
//
//                 <div className="map-column">
//                     <Map/>
//                 </div>
//
//                 <div className='mailfont'>
//                     <p style={{marginBottom: 7}}>
//                         <span className='mail outline'>
//                             <img src={telegram} alt="Telegram" style={{width: 16, height: 16}}/>
//                         </span>
//
//                         <span>
//                             <a className="link2" href="https://t.me/computer_programming_school">
//                                 Telegram Channel: computer programming school
//                             </a>
//                         </span>
//                     </p>
//                     <p>
//                         <span className='mail outline'>
//                             <MailOutlined/>
//                         </span>
//                         <span>
//                              <a className="link2" href="mailto:esmaeilsadeghijob@gmail.com">
//                                 esmaeilsadeghijob@gmail.com
//                             </a>
//                         </span>
//                         {/* <span className='mail outline'> */}
//                         {/*     <MailOutlined/> */}
//                         {/* </span> */}
//
//                     </p>
//                 </div>
//
//                 <a href={`mailto:${contact.email}`}>
//                     <button type='button' className='btn btn--outline vazirlight'>
//                     ارسال ایمیل
//                     </button>
//                 </a>
//             </section>
//
//         // </div>
//     );
// };


// const Contact = () => {
//
//     if (!contact.email) return null
//
//     return (
//         <section className='section contact center' id='contact'>
//             <h2 className='section__title sogand' style={{fontSize: 30}}>
//                 تماس با ما
//             </h2>
//
//             <div className='about__contact center'>
//
//                 <div className="map-column">
//                     <Map/>
//                 </div>
//
//                 <div className='mailfont'>
//                     <p style={{marginBottom: 7}}>
//                         {/* eslint-disable-next-line jsx-a11y/alt-text */}
//                         <span className='mail outline'><img src={telegram} style={{width: 16, height: 16}}/></span>
//                         {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
//                         <span><a className="link2"
//                                  href="https://t.me/computer_programming_school"> esmaeilsadeghi</a></span>
//                     </p>
//                     <p>
//                         {/* </div> */}
//                         {/* <p className='link footer__link mail ' > */}
//                         {/* <div className='mailfont'> */}
//                         {/* <span className='link2' href="mailto:esmaeilsadeghijob@gmail.com"><MailOutlined/> </span> */}
//                         <span className='mail outline'><MailTwoTone/></span>
//                         <span><a className="link2"
//                                  href="mailto:esmaeilsadeghijob@gmail.com">  esmaeilsadeghijob@gmail.com</a></span>
//                         {/* <span>esmaeilsadeghijob@gmail.com</span> */}
//                     </p>
//                     <p>
//                         {/* </div> */}
//                         {/* <p className='link footer__link mail ' > */}
//                         {/* <div className='mailfont'> */}
//                         <span className='mail outline'><MessageOutlined/></span>
//                         <span><a className="link2" href="sms:+989122273119"> message</a></span>
//                     </p>
//                     <p>
//                         {/* </div> */}
//                         {/* <p className='link footer__link mail ' > */}
//                         {/* <div className='mailfont'> */}
//                         <span className='mail outline'><MobileOutlined/></span>
//                         <span><a className="link2" href="tel:+989122273119"> 912 22 73 119</a></span>
//                     </p>
//                 </div>
//
//
//                 <a href={`mailto:${contact.email}`}>
//         <span type='button' className='btn btn--outline vazirlight'>
//           ارسال ایمیل
//         </span>
//                 </a>
//             </div>
//
//         </section>
//     )
// }

export default Contact
