import React, { useEffect, useState } from 'react'
import './ScrollToTop.css'
import {ArrowUpOutlined, UpCircleOutlined} from "@ant-design/icons";
import { BackTop } from 'antd';


const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const toggleVisibility = () =>
      window.pageYOffset > 500 ? setIsVisible(true) : setIsVisible(false)

    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  return isVisible ? (
    <div className='scroll-top'>
      <a href='#top'>
        {/* <ArrowUpwardIcon fontSize='large' /> */}
        {/* <ArrowUpOutlined/> */}
        <BackTop style={{ fontSize: '180%'}}>
          <ArrowUpOutlined style={{fontSize: '130%'}} />
          {/* <UpCircleOutlined /> */}
        </BackTop>
      </a>
    </div>
  ) : null
}

export default ScrollToTop
