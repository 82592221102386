import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from './contexts/theme'
import './App.css'
import Software from "./components/Pages/Software/Software";
import Home from "./components/Home/Home";
import Automaton from "./components/Pages/Automaton/Automaton";
import Java from "./components/Pages/Java/Java";
import Question from "./components/Pages/Questions/Question";


// const App = () => {
function App() {
    // const [{themeName}] = useContext(ThemeContext)
    // const d = new Date();
    // const options = {weekday: 'long', month: 'long', day: 'numeric'};
    // const option = {year: 'numeric'};

    // let y;
    // let x;

    return (

        // <div id='top' className={`${themeName} app`}>
        //     {/* <Affix style={{position: 'additions'}}> */}
        //
        //
        //     <Header/>
        //     {/* </Affix> */}
        //     <Router>
        //         <Routes>
        //             <Route path="/" element={<App/>}/>
        //             <Route path="/software" element={<Software/>}/>
        //         </Routes>
        //     </Router>
        //
        //     <main>
        //         <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>
        //
        //         {/* <Link to="/software">software</Link> */}
        //         <CarouselFerris/>
        //         <About/>
        //         <Projects/>
        //         <Skills/>
        //         <Contact/>
        //     </main>
        //
        //     <ScrollToTop/>
        //     <Footer/>
        // </div>

        <>
            <ThemeProvider>

                <Router>
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={<Home/>}
                        />

                        <Route
                            path="/software"
                            element={<Software/>}
                        />

                        <Route
                            path="/automaton"
                            element={<Automaton/>}
                        />

                        <Route
                            path="/java"
                            element={<Java/>}
                        />

                        <Route
                            path="/question"
                            element={<Question/>}
                        />
                    </Routes>
                </Router>
            </ThemeProvider>


            {/* <div> */}
            {/*     <Link to="/software">Go to page</Link> */}
            {/* </div> */}
        </>
    )
}

export default App
