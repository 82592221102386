import uniqid from 'uniqid'
import {CodeOutlined, GithubFilled, InfoCircleOutlined, LinkedinFilled} from '@ant-design/icons';
import './ProjectContainer.css'
import {CloudDownloadOutlined, LoginOutlined, LinkOutlined} from "@ant-design/icons/lib/icons";

const ProjectContainer = ({project}) => (
    <div className='project'>
        <h3 className='lalezar'>{project.name}</h3>

        <p className='project__description bNazaninF'>{project.description}</p>
        {project.stack && (
            <ul className='project__stack lalezarLink'>
                {project.stack.map((item) => (
                    <li key={uniqid()} className='project__stack-item'>
                        {item}
                    </li>
                ))}
            </ul>
        )}

        {project.book && (
            <a
                href={project.book}
                aria-label='book'
                className='link link--icon'
            >
                <CloudDownloadOutlined style={{fontSize: '120%'}}/>
            </a>
        )}

        {project.document && (
            <a
                href={project.document}
                aria-label='Document'
                className='link link--icon'
            >
                <LoginOutlined style={{fontSize: '120%'}}/>
            </a>
        )}

        {project.application && (
            <a
                href={project.application}
                aria-label='App link'
                className='link link--icon'
            >
                <LinkOutlined style={{fontSize: '120%'}} />
            </a>
        )}

        {project.details && (
            <a
                href={project.details}
                aria-label='App link'
                className='link link--icon'
            >
                <InfoCircleOutlined  style={{fontSize: '120%'}} />
            </a>
        )}

        {project.tools && (
            <a
                href={project.tools}
                aria-label='App link'
                className='link link--icon'
            >
                <CodeOutlined  style={{fontSize: '120%'}} />
            </a>
        )}

    </div>
)

export default ProjectContainer
